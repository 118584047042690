import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";
import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { ContactLinks, Grid, Link, SEO } from 'components';
import { useNavIntro } from 'hooks';
import { Arrow } from 'svgs';
import { className, getTeamMemberRoute, renderText } from 'utils';
import styles from './teamMemberPage.module.scss';
var BGCOLOR = styles.varBgColor;

var TeamMemberPage = function TeamMemberPage(_ref) {
  var _ref$data$member = _ref.data.member,
      bio = _ref$data$member.bio,
      email = _ref$data$member.email,
      linkedIn = _ref$data$member.linkedIn,
      medium = _ref$data$member.medium,
      name = _ref$data$member.name,
      portrait = _ref$data$member.portrait,
      role = _ref$data$member.role,
      seo = _ref$data$member.seo,
      twitter = _ref$data$member.twitter,
      pathname = _ref.location.pathname,
      _ref$pageContext = _ref.pageContext,
      next = _ref$pageContext.next,
      previous = _ref$pageContext.previous;
  useNavIntro();
  return React.createElement(Grid, className(styles.pageWrapper, !portrait && styles.pageWrapperNophoto), React.createElement(SEO, Object.assign({}, seo, {
    pathname: pathname
  })), React.createElement("section", {
    className: styles.container
  }, React.createElement("h1", {
    className: styles.name
  }, name), React.createElement("p", {
    className: styles.role
  }, role), portrait && React.createElement("div", {
    className: styles.portrait
  }, React.createElement(Img, {
    alt: portrait.title,
    backgroundColor: BGCOLOR,
    className: styles.portraitImage,
    fluid: portrait.fluid
  })), bio && React.createElement("div", {
    className: styles.bio
  }, renderText(bio)), React.createElement(ContactLinks, {
    className: styles.contactLinks,
    email: email,
    linkedIn: linkedIn,
    medium: medium,
    twitter: twitter
  })), React.createElement("div", {
    className: styles.nav
  }, [previous, next].map(function (_ref2) {
    var name = _ref2.name,
        slug = _ref2.slug;
    return React.createElement(Link, {
      key: slug,
      className: styles.navBtn,
      to: getTeamMemberRoute(slug)
    }, React.createElement("span", {
      className: styles.navBtnText
    }, name), React.createElement(Arrow, {
      className: styles.arrow
    }));
  })));
};

TeamMemberPage.propTypes = {
  data: PropTypes.shape({
    member: PropTypes.shape({
      bio: PropTypes.object.isRequired,
      email: PropTypes.string,
      linkedIn: PropTypes.string,
      medium: PropTypes.string,
      name: PropTypes.string.isRequired,
      portrait: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired
      }),
      role: PropTypes.string.isRequired,
      twitter: PropTypes.string,
      seo: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    }).isRequired,
    previous: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
export default TeamMemberPage;
export var query = "3300740917";